import { createStore } from "vuex";
import tagsView from "./modules/tagsView";
import { login, logout } from "@/api/login";
import { setToken, removeToken } from "@/utils/auth";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      const password = userInfo.password;
      return new Promise((resolve, reject) => {
        login(username, password)
          .then((res) => {
            res = res.data;
            // 设置 token
            setToken(res);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            // 清除token
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: { tagsView },
});
