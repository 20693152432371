import { setPaymentPasswordApi } from "@/api/set";
export default {
  name: "SetPaymentPasswordPopupView",
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      isShowPopup: false,
      setPaymentPasswordLoading: false,
      setPaymentPasswordForm: {
        oldPassword: "",
        password: ""
      },
      setPaymentPasswordFormRules: {
        oldPassword: [{
          required: true,
          trigger: "blur",
          message: "Old Password cannot be empty"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "New Password cannot be empty"
        }]
      }
    };
  },
  methods: {
    openPopup() {
      this.isShowPopup = true;
    },
    closePopup() {
      this.isShowPopup = false;
    },
    setPaymentPasswordSubmit() {
      this.setPaymentPasswordLoading = true;
      setPaymentPasswordApi(this.setPaymentPasswordForm).then(() => {
        this.$message.success("Set Payment Password Success!");
        this.setPaymentPasswordLoading = false;
        this.isShowPopup = false;
        this.$refs.setPaymentPasswordFormRef.resetFields();
      }).catch(() => {
        this.setPaymentPasswordLoading = false;
      });
    }
  }
};