import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12e68c8e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "layout-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Aside = _resolveComponent("Aside");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_Header = _resolveComponent("Header");
  const _component_el_header = _resolveComponent("el-header");
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_aside, {
      class: "sidebar-container"
    }, {
      default: _withCtx(() => [_createVNode(_component_Aside)]),
      _: 1
    }), _createVNode(_component_el_container, null, {
      default: _withCtx(() => [_createVNode(_component_el_header, null, {
        default: _withCtx(() => [_createVNode(_component_Header)]),
        _: 1
      }), _createVNode(_component_el_main, null, {
        default: _withCtx(() => [_createVNode(_component_router_view, null, {
          default: _withCtx(({
            Component
          }) => [_createVNode(_component_el_collapse_transition, null, {
            default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
              include: $options.cachedViews
            }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1032, ["include"]))]),
            _: 2
          }, 1024)]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}