import "core-js/modules/es.array.push.js";
import bus from "../utils/eventBus";
import router from "@/router";
import SetLoginPassword from "@/components/SetLoginPasswordPopupView";
import SetPaymentPassword from "@/components/SetPaymentPasswordPopupView";
export default {
  name: "HeaderView",
  components: {
    SetLoginPassword,
    SetPaymentPassword
  },
  props: {},
  data() {
    return {
      isCollapse: true
    };
  },
  methods: {
    // 导航折叠
    isCollapseChange() {
      bus.emit("isCollapseChange", this.isCollapse);
      this.isCollapse = !this.isCollapse;
    },
    // 设置登录密码点击
    openSetLoginPasswordPopup() {
      this.$refs.setLoginPasswordRef.openPopup();
    },
    // 设置登录密码点击
    openSetPaymentPasswordPopup() {
      this.$refs.setPaymentPasswordRef.openPopup();
    },
    // 退出登录
    handleLogout() {
      this.$store.dispatch("Logout").then(() => {
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.value.fullPath
          }
        });
      }).catch(() => {});
    }
  }
};