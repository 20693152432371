import Header from "../components/HeaderView.vue";
import Aside from "../components/AsideView.vue";
export default {
  name: "HelloWorld",
  components: {
    Header,
    Aside
  },
  props: {},
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      return this.$route.path;
    }
  }
};