import { setLoginPasswordApi } from "@/api/set";
export default {
  name: "SetLoginPasswordPopupView",
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 50
    },
    height: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      isShowPopup: false,
      setLoginPasswordLoading: false,
      setLoginPasswordForm: {
        oldPassword: "",
        password: ""
      },
      setLoginPasswordFormRules: {
        oldPassword: [{
          required: true,
          trigger: "blur",
          message: "Old Password cannot be empty"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "New Password cannot be empty"
        }]
      }
    };
  },
  methods: {
    openPopup() {
      this.isShowPopup = true;
    },
    closePopup() {
      this.isShowPopup = false;
      this.$refs.setLoginPasswordFormRef.resetFields();
    },
    setLoginPasswordSubmit() {
      this.setLoginPasswordLoading = true;
      setLoginPasswordApi(this.setLoginPasswordForm).then(() => {
        this.$message.success("Set Login Password Success!");
        this.setLoginPasswordLoading = false;
        this.isShowPopup = false;
        this.$refs.setLoginPasswordFormRef.resetFields();
      }).catch(() => {
        this.setLoginPasswordLoading = false;
      });
    }
  }
};