import bus from "../utils/eventBus";
export default {
  name: "AsideView",
  props: {},
  data() {
    return {
      isCollapse: false,
      AsideIndex: "1",
      loginAccount: localStorage.getItem("loginAccount") || ""
    };
  },
  mounted() {
    bus.on("isCollapseChange", data => {
      this.isCollapse = data;
    });
  },
  methods: {}
};